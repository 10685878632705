import React, { useState, useEffect, useCallback } from 'react'
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from 'react-redux';
import { getStaffs, createUser, updateUser } from '../store/slices/hcpSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import debounce from 'lodash/debounce'

import { swalDel, Toast } from '../utils/SwalUti';
import { getWithParams, del } from '../utils/fetchAPI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'



function Staff() {
  const dispatch = useDispatch()
  const { staffs, pagination, isLoading } = useSelector(state => state.hcp)
  const hcpID = useSelector(state => state.auth.user.hcp._id)
  const [length, setLength] = useState(10)
  const [searchQuery, setSearchQuery] = useState('')
  const initialState = {
    first: "",
    last: "",
    email: "",
    mobile: "",
    password: "",
    hcp: ""
  };
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState(initialState)
  const [createOrUpdateModalShow, setCreateOrUpdateModalShow] = useState(false);
  const [isOnEdit, setIsOnEdit] = useState(false)
  const [emailModalShow, setEmailModalShow] = useState(false)
  const { current_page, per_page, total } = pagination

  function handleChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value
    });
  }

  const handleClose = () => {
    setCreateOrUpdateModalShow(false)
    setEmailModalShow(false)
  };

  const handleEmailModal = (e) => {
    e.preventDefault();
    setFormData(initialState)
    setErrorMessage('')
    setEmailModalShow(true)
  }

  const verifyOrInviteEmail = (e) => {
    e.preventDefault();
    // verify email and start user create journey
    getWithParams('/verify-email', { email: formData.email })
        .then(res => {
          setEmailModalShow(false)
          handleAddModal()
        })
        .catch(err => setErrorMessage(err.response.data.message))
  }

  const createOrUpdate = (e) => {
    e.preventDefault();
    // If on edit mode handle user edit process
    if (isOnEdit) {
      dispatch(updateUser(formData))
          .then(_ => {
            setCreateOrUpdateModalShow(false)
            index(current_page)
            Toast.fire({
              icon: 'success',
              title: 'Updated successfully'
            })
          })
    }
    // User create process here
    else {
      dispatch(createUser(formData))
          .then(_ => {
            setCreateOrUpdateModalShow(false)
            index(current_page)
            Toast.fire({
              icon: 'success',
              title: 'Created successfully'
            })
          })
    }
  }

  const handleAddModal = () => {
    setIsOnEdit(false)
    setFormData({
      ...formData,
      hcp: hcpID,
      role: "staff",
    })
    setCreateOrUpdateModalShow(true)
  }

  const handleEditModal = (id) => {
    setIsOnEdit(true)
    const { access, dateOfBirth, notificationId, role, voipNotificationId, __v, ...rest } = staffs.find(staff => staff._id === id)
    let date = new Date(dateOfBirth)
    setFormData({ dateOfBirth: date, ...rest })
    setCreateOrUpdateModalShow(true)
  };

  const handleDelete = (id) => {
    swalDel('This Doctor entity would be deleted permanently!')
        .then((result) => {
          if (result.value) {
            del(`/user/${id}`)
                .then(_ => {
                  Toast.fire({
                    icon: 'success',
                    title: 'Deleted!'
                  })
                  index(current_page)
                })
                .catch(error => {
                  console.log(error);
                })
          }
        })
  }

  const index = (page = 1) => {
    dispatch(getStaffs({ page: page, per_page: length, search: searchQuery }))
  }
  const delayedQuery = useCallback(debounce(index, 500), [searchQuery, length])

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [searchQuery, delayedQuery, length]);

  return (
      <div className="content-wrapper">
        <section className="content pt-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="float-left">
                      <h2 className="card-title pt-1">Staffs </h2>
                      <a href='' onClick={(e) => handleEmailModal(e)} className="ml-4 card-title">
                        <FontAwesomeIcon icon={faUserPlus}/> Add Staff
                      </a>
                      <a href='' className="ml-4 card-title">
                        {
                          isLoading ?
                              (
                                  <div className="loader"></div>
                              )
                              : ''
                        }
                      </a>
                    </div>
                    <div className="float-right">
                      <div className="input-group" >
                        <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control form-control-sm" type="text" placeholder="Search" />
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body" style={{ overflowX: 'auto' }} >

                    <table id="example2" className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}">
                      <thead>
                      <tr>
                        <th>FIRST NAME</th>
                        <th>LAST NAME</th>
                        <th>EMAIL</th>
                        <th>CELL PHONE</th>
                        <th className="text-center" >ACTIONS</th>
                      </tr>
                      </thead>
                      <tbody>
                      {!!staffs && staffs.map((staff, index) => (
                          <tr key={index} >
                            <td>{staff.first}</td>
                            <td>{staff.last}</td>
                            <td>{staff.email}</td>
                            <td>{staff.mobile}</td>
                            <td className="text-center" >
                              <div className="btn-group btn-group-sm" role="group" aria-label="single group">
                                <button type="button" className="btn btn-sm" onClick={() => handleEditModal(staff._id)} >
                                  <FontAwesomeIcon icon={faEdit} className='text-green'/>
                                </button>
                                <button type="button" className="btn btn-sm" onClick={() => handleDelete(staff._id)} >
                                  <FontAwesomeIcon icon={faTrash} className='text-red'/>
                                </button>
                              </div>
                            </td>
                          </tr>
                      ))
                      }
                      </tbody>
                    </table>

                    {/* user email verify modal starts */}
                    <Modal
                        show={emailModalShow}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                      <Modal.Header closeButton className='pb-2' >
                        <Modal.Title>Staff Registration</Modal.Title>
                      </Modal.Header>
                      <form onSubmit={verifyOrInviteEmail}>
                        <Modal.Body>
                          <div className="form-row">
                            <h5 className='text-center'>Please enter the staffs's email address to start the registration process.</h5>

                            <div className="mt-3 form-group col-md-12 px-3">
                              <input type="email" name="email" className="form-control" value={formData.email} onChange={handleChange} required />
                              {errorMessage ? <span className="text-danger mt-2">{errorMessage}</span> : null}
                            </div>
                          </div>

                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" type="submit">Submit</Button>
                        </Modal.Footer>
                      </form>
                    </Modal>
                    {/* user email verify modal ends here */}

                    {/* staff edit/create modal starts */}
                    <Modal
                        show={createOrUpdateModalShow}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                      <Modal.Header closeButton className='pb-2' >
                        {
                          isOnEdit ?
                              <Modal.Title>Existing Staff Form</Modal.Title>
                              :
                              <Modal.Title>Add New Staff</Modal.Title>
                        }
                      </Modal.Header>
                      <form onSubmit={createOrUpdate}>
                        <Modal.Body>
                          <div className="form-row">
                            <div className="form-group col-md-6 px-3">
                              <label>First Name</label>
                              <input type="text" name="first" className="form-control" value={formData.first} onChange={handleChange} />
                            </div>
                            <div className="form-group col-md-6 px-3">
                              <label>Last Name</label>
                              <input type="text" name="last" className="form-control" value={formData.last} onChange={handleChange} />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-6 px-3">
                              <label>Email</label>
                              <input type="email" name="email" className="form-control" value={formData.email} disabled />
                            </div>
                            <div className="form-group col-md-6 px-2">
                              <label>Cell Phone</label>
                              <input type="text" name="mobile" className="form-control" value={formData.mobile} onChange={handleChange} />
                            </div>
                          </div>

                          <div className="form-row">
                            <div className="form-group col-md-6 px-2">
                              <label>Password</label>
                              <input type="password" name="password" className="form-control" value={formData.password} onChange={handleChange} />
                            </div>
                          </div>

                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={handleClose}>
                            Close
                          </Button>
                          <Button variant="primary" type="submit">Save</Button>
                        </Modal.Footer>
                      </form>
                    </Modal>
                    {/* staff edit/create modal ends here */}

                  </div>
                  {/* /.card-body */}
                  <div className="card-footer">
                    <div className="float-left">
                      <div className="dataTables_info" id="example2_info" role="status" aria-live="polite">
                        {/* <div className="float-left">
                      </div> */}
                        {
                          !!staffs.length > 0 ?
                              (
                                  <>
                                    <select onChange={(e) => setLength(e.target.value)}>
                                      <option value={10}>10 entries </option>
                                      <option value={20}>20 entries </option>
                                      <option value={50}>50 entries </option>
                                      <option value={100}>100 entries </option>
                                    </select> &nbsp;
                                    Showing {((current_page - 1) * per_page) + 1} to {total > current_page * per_page ? current_page * per_page : total} of {total} entries
                                  </>
                              )
                              :
                              null
                        }
                      </div>
                    </div>
                    <div className="float-right">
                      <Pagination
                          activePage={parseInt(current_page)}
                          itemsCountPerPage={parseInt(per_page)}
                          totalItemsCount={parseInt(total)}
                          // pageRangeDisplayed={5}
                          onChange={(current_page) => index(current_page)}
                          itemClass="page-item"
                          linkClass="page-link"
                      />
                    </div>
                  </div>
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </section>
      </div>
  )
}

export default Staff