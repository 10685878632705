import React from 'react'

function AppFooter() {
    return (
        <footer className="main-footer">
            <strong>Copyright © 2014-2021 <a href="#">RiteCare</a>.</strong> All rights reserved.
        </footer>
    )
}

export default AppFooter