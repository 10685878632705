import React, {useState, useEffect, useRef} from 'react'
import AgoraRTC, {AREAS, ICameraVideoTrack} from "agora-rtc-sdk-ng";
import {useParams} from 'react-router-dom';
import {get, post, put} from '../utils/fetchAPI';
import '../assets/rtcCall.css'
import firebaseApp from '../firebase'
import {doc, setDoc, onSnapshot, getFirestore} from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faVideoSlash, faMicrophone, faMicrophoneSlash, faPhone } from '@fortawesome/free-solid-svg-icons'


function RTCVideoCall() {
    const {id} = useParams()
    const [callData, setCallData] = useState({})
    const [localAudioEnabled, setLocalAudioEnabled] = useState(true)
    const [localVideoEnabled, setLocalVideoEnabled] = useState(true)
    const [remoteAudioEnabled, setRemoteAudioEnabled] = useState(false)
    const [remoteVideoEnabled, setRemoteVideoEnabled] = useState(false)
    const [remoteUser, setRemoteUser] = useState(undefined)
    const [isInvalidUrl, setIsInvalidUrl] = useState(false)

    let unsub = useRef(null)
    let rtc = useRef({
        localAudioTrack: null,
        localVideoTrack: null,
    })
    let agoraEngine = useRef(null)


    const firestoreDB = getFirestore(firebaseApp);
    const docRef = doc(firestoreDB, "calls", id)

    const handleLeave = async () => {
        console.log("handleLeave : call")

        rtc.current.localAudioTrack.close()
        rtc.current.localVideoTrack.close()

        await agoraEngine.current.leave();
        if (remoteUser === undefined) {
            await updateCallStatus(id, {status: 'Pending'})
        }
        if (unsub.current) unsub.current()
    }

    const muteAudio = async () => {
        await rtc.current.localAudioTrack.setEnabled(false)
        setLocalAudioEnabled(false)
    }

    const unmuteAudio = async () => {
        await rtc.current.localAudioTrack.setEnabled(true)
        setLocalAudioEnabled(true)
    }

    const muteVideo = async () => {
        await rtc.current.localVideoTrack.setEnabled(false)
        setLocalVideoEnabled(false)
    }

    const unmuteVideo = async () => {
        await rtc.current.localVideoTrack.setEnabled(true)
        setLocalVideoEnabled(true)
    }

    const generateNotificationPayload = (fcmToken) => {
        return {
            data: {
                waitingRoom: 'true'
            },
            android: {priority: "high", ttl: 0},
            apns: {
                payload: {
                    aps: {
                        badge: 0,
                        contentAvailable: true
                    },
                    waitingRoom: 'true'
                }
            },
            token: fcmToken
        }
    }

    const updateCallStatus = async (id, payload) => {
        console.log('updateCallStatus:', payload)
        await put(`/changeCallStatus/${id}`, payload)
            .then(_ => console.log('call status changed'))
            .catch(err => console.log(err))
    }

    const initializeRTC = async (data) => {
        AgoraRTC.setArea([AREAS.NORTH_AMERICA])
        agoraEngine.current = AgoraRTC.createClient({mode: "rtc", codec: "vp8"});

        rtc.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

        // Play the local track
        let localContainer = document.getElementById("me");
        let userIcon = document.querySelector('.fa-user-circle');
        userIcon.className += ' hidden';

        rtc.current.localVideoTrack.play(localContainer);


        agoraEngine.current.on('token-privilege-will-expire', async () => {
            console.log('token-privilege-will-expire', Date.now())
            const response = await get('/agora/refreshToken/' + id)
            console.log('response', response)
            if (response?.data?.token) {
                console.log('refreshToken', response?.data.token)
                await agoraEngine.current.renewToken(response?.data.token);
            }
        })

        agoraEngine.current.on('token-privilege-did-expire', async () => {
            console.log('token-privilege-did-expire', Date.now())
            setIsInvalidUrl(true)
        })

        agoraEngine.current.on('user-joined', async (user) => {
            setRemoteUser(user)
        })

        agoraEngine.current.on('user-left', async (user, reason) => {
            console.log('user-left: call drop reason: ' + reason)
            if (reason === 'Quit') await handleLeave();
            //TODO This event is called when remote user left
        })

        // Set up event listeners for remote users publishing or unpublishing tracks
        agoraEngine.current.on('user-published', async (user, mediaType) => {
            await agoraEngine.current.subscribe(user, mediaType);
            // subscribe when a user publishes
            if (mediaType === 'video') {
                setRemoteVideoEnabled(true)
            }
            if (mediaType === 'audio') {
                setRemoteAudioEnabled(true)
                user.audioTrack.play(); // audio does not need a DOM element
            }
        });

        agoraEngine.current.on('user-unpublished', async (user, mediaType) => {
            if (mediaType === 'video') {
                setRemoteVideoEnabled(false)
            }
            if (mediaType === 'audio') {
                setRemoteAudioEnabled(false)
            }
        });

        try {
            setDoc(docRef, {
                patient: data.patientName,
                patientWaiting: true
            }).then(async () => {
                unsub.current = onSnapshot(docRef, async (doc) => {
                    const document = doc.data()
                    if (document.doctorJoined) {
                        let appId = '3798652039934cd08d1e568d86a7c9d4';
                        let channel = callData?.callId;
                        let token = callData?.rtcToken;
                        if (channel && token) {
                            await agoraEngine.current.join(appId, callData?.callId, callData?.rtcToken);
                            await agoraEngine.current.publish([rtc.current.localAudioTrack, rtc.current.localVideoTrack]);
                            await updateCallStatus(id, {status: 'In-Call'})

                        }
                    }
                })
                // send notification to doctor
                await updateCallStatus(callData._id, {status: 'Waiting'})
                const notificationPayload = generateNotificationPayload(callData.doctor.notificationId)
                post('sendFcm', notificationPayload)

            });
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    useEffect(() => {
        if (callData.callId) {
            initializeRTC(callData).then(r => console.log('initializeRTC'))
        }
    }, [callData])

    useEffect(() => {
        get(`/call/${id}`)
            .then(({data}) => {
                // if call status is completed then handle invalid message
                if (data.status === 'Pending' || data.status === 'Waiting') {
                    setCallData(data)
                } else {
                    setIsInvalidUrl(true)
                }
            })
            .catch(err => {
                /// handle if there is no data then handle invalid message
                setIsInvalidUrl(true)
            })

    }, [id])

    useEffect(() => {
        if (remoteVideoEnabled) {
            let remoteContainer = document.getElementById("remote-video");
            while (remoteContainer.hasChildNodes()) {
                remoteContainer.removeChild(remoteContainer.firstChild);
            }
            const remotePlayerContainer = document.createElement("div");
            remotePlayerContainer.style.width = "auto";
            remotePlayerContainer.style.height = "100%";
            // Specify the ID of the DIV container. You can use the uid of the remote user.
            remotePlayerContainer.id = 'remote ' + remoteUser.uid.toString();

            remoteContainer.appendChild(remotePlayerContainer);
            remoteUser.videoTrack.play(remotePlayerContainer);
        }
    }, [remoteVideoEnabled]);

    return (
        <>
            {!isInvalidUrl ?
                <div className="page-hero d-flex align-items-center justify-content-center">
                    <div className="video-content">
                        <section className="holder holder--video">
                            <div id="remote" className="holder--video-remote waiting">
                                {
                                    remoteVideoEnabled ?
                                        <div id="remote-video" style={{height: "100%", width: "auto"}}></div> :
                                        <div className="remote-info" style={{height: "auto", width: "100%"}}>
                                            {
                                                callData?.doctor?.image ?
                                                    <img src={callData?.doctor?.image} alt=""/>
                                                    :
                                                    null
                                            }
                                            <div className="text-center" style={{marginTop: 10}}>
                                                <span className="brand-text font-weight-bold"
                                                      style={{fontSize: 20}}>{callData?.doctor?.first} {callData?.doctor?.last}</span>
                                                <br/>
                                                {
                                                    remoteUser === undefined ?
                                                        <span className="brand-text font-weight-light">Please wait. Doctor will join soon</span> : null
                                                }

                                            </div>
                                        </div>
                                }


                            </div>
                            <div id="me" className="holder--video-me">
                                <i className="fas fa-user-circle fa-lg"></i>
                            </div>
                        </section>
                        {/*These are the controller buttons*/}
                        <section className="holder options">
                            <div className="holder--options">
                                {
                                    localVideoEnabled ?
                                        <button onClick={muteVideo} className="holder--options-option" id="btnCam">
                                            <FontAwesomeIcon icon={faVideo} size="3x"/>
                                        </button>
                                        :
                                        <button onClick={unmuteVideo} className="holder--options-option" id="btnCam">
                                            <FontAwesomeIcon icon={faVideoSlash} className='text-red' size="3x"/>
                                        </button>
                                }
                                {
                                    localAudioEnabled ?
                                        <button onClick={muteAudio} className="holder--options-option" id="btnMic">
                                            <FontAwesomeIcon icon={faMicrophone} size="3x"/>
                                        </button>
                                        :
                                        <button onClick={unmuteAudio} className="holder--options-option" id="btnMic">
                                            <FontAwesomeIcon icon={faMicrophoneSlash} className='text-red' size="3x"/>
                                        </button>
                                }
                                <button onClick={handleLeave} className=" holder--options-option" id="btnStop">
                                    <FontAwesomeIcon icon={faPhone} className='text-red' size="3x"/>
                                    {/* <i class="fas fa-phone-slash"></i> */}
                                </button>
                            </div>
                        </section>
                    </div>
                </div> :
                <div className="page-hero d-flex align-items-center justify-content-center">
                    <div className="card" style={{width: '340px'}}>
                        <div className="text-center card-body register-card-body m-3">
                            <span
                                className="text-danger">Invalid Url. Please ask your doctor to send you a new link.</span>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default RTCVideoCall