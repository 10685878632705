import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWithParams } from "../../utils/fetchAPI";

const initialState = {
    calls: [],
    pagination: {
        current_page: 1
    },
    isLoading: false,
    error: null
}
export const getCalls = createAsyncThunk(
    'hcp/getCalls',
    async (payload, { getState, rejectWithValue }) => {
        const state = getState()
        const hcpID = state.auth.user?.hcp?._id
        try {
            const response = await getWithParams(`/getCalls/${hcpID}`, payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const callSlice = createSlice({
    name: 'call',
    initialState,
    extraReducers: {
        [getCalls.pending]: (state) => {
            state.isLoading = true
            state.error = null
        },
        [getCalls.fulfilled]: (state, action) => {
            state.isLoading = false
            let {calls, ...rest} = action.payload
            state.calls = calls
            state.pagination = rest
            state.error = null
        },
        [getCalls.rejected]: (state, action) => {
            state.isLoading = false
            state.error = action.payload.message
        },
    }
})

export default callSlice.reducer

