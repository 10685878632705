import {initializeApp} from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCIBjSjk8iYE9tz6zeF6naEy8kQNIQR4h0",
    authDomain: "ritecare-telemedicine.firebaseapp.com",
    projectId: "ritecare-telemedicine",
    storageBucket: "ritecare-telemedicine.appspot.com",
    messagingSenderId: "115064904700",
    appId: "1:115064904700:web:51c2c114562d561654b079",
    measurementId: "G-K3LQFDTV6H"
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp
