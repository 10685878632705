import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { post } from "../../utils/fetchAPI";

const initialState = {
    user: '' || JSON.parse(localStorage.getItem('user')),
    isLoggedIn: !!JSON.parse(localStorage.getItem('user'))?.token,
    isLoading: false,
    authError: null,
}

export const login = createAsyncThunk(
    'auth/login',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await post('/webLogin', payload)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        removeAuthInfo: (state, action) => {
            localStorage.removeItem('user')
            state.user = '';
            state.isLoggedIn = false;
        }
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.isLoading = true
            state.authError = null
        },
        [login.fulfilled]: (state, action) => {
            state.isLoading = false
            state.isLoggedIn = true
            state.user = action.payload
            localStorage.setItem('user', JSON.stringify(action.payload));
            state.authError = null
        },
        [login.rejected]: (state, action) => {
            state.isLoading = false
            state.authError = action.payload.message
        },
    }
})

export default authSlice.reducer;
export const { removeAuthInfo } = authSlice.actions;