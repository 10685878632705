import React, {useState} from 'react'
import {Link, NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faStethoscope, faAddressCard, faClock, faChartLine } from '@fortawesome/free-solid-svg-icons'

function AppSidebar() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))

    return (
        // <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <aside className="main-sidebar side-bar-color elevation-4">
            {/* Brand Logo */}
            <Link to="/home" className="brand-link">
                <div className="text-center" style={{marginTop:25, marginBottom:25,}}>
                    <img src="../../dist/img/launcher_icon.png"  className="rounded img-fluid"
                         alt="..." style={{width:"65%"}}/>
                </div>
                {/*<div className="text-center" style={{marginTop:25, marginBottom:25}}>*/}
                {/*    <span className="brand-text font-weight-light">RiteCare Telemedicine</span>*/}
                {/*</div>*/}
            </Link>
            {/* Sidebar */}
            <div className="sidebar">
                {/* Sidebar user (optional) */}
                <div className="user-panel mt-2 pb-2 mb-2 d-flex">
                    <div className="image">
                        {/* <img src={!!user ? `${user.image}` : 'No User'} className="img-circle elevation-2"
                             alt="User Image"/> */}
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">{!!user ? `${user.first} ${user.last}` : 'No User'}</a>
                    </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-1">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                        data-accordion="false">
                        {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}

                        <li className="nav-item">
                            <NavLink to="/home" className="nav-link">
                                <FontAwesomeIcon icon={faHome}/>
                                <p> Home</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="patient" className="nav-link">
                                <FontAwesomeIcon icon={faUser}/>
                                <p> Patient</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="healthcare" className="nav-link">
                                <FontAwesomeIcon icon={faStethoscope}/>
                                <p> HealthCare </p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="staff" className="nav-link">
                                <FontAwesomeIcon icon={faAddressCard}/>

                                <p> Staff </p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="pending-requests" className="nav-link">
                                <FontAwesomeIcon icon={faClock}/>
                                <p> Pending Request
                                </p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="analytics" className="nav-link">
                                <FontAwesomeIcon icon={faChartLine}/>
                                <p> Analytics
                                </p>
                            </NavLink>
                        </li>
                        {/* for future use */}
                        {/* <li className="nav-item">
                            <a href="javascript:void(0);" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt" />
                                <p>
                                    Dashboard
                                    <i className="right fas fa-angle-left" />
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <a href="../../index.html" className="nav-link">
                                        <i className="far fa-circle nav-icon" />
                                        <p>Dashboard v1</p>
                                    </a>
                                </li>
                            </ul>
                        </li> */}
                    </ul>
                </nav>
                {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
        </aside>
    )
}

export default AppSidebar