import React, {useState, useEffect, useCallback, useRef} from 'react'
import Pagination from "react-js-pagination";
import {useDispatch, useSelector} from 'react-redux';
import {getDoctors, createUser, updateUser} from '../store/slices/hcpSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import debounce from 'lodash/debounce'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {swalDel, Toast} from '../utils/SwalUti';
import {get, getWithParams, del, post} from '../utils/fetchAPI';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'


function Healthcare() {
    const dispatch = useDispatch()
    const {doctors, pagination, isLoading} = useSelector(state => state.hcp)
    const hcpID = useSelector(state => state.auth.user.hcp._id)
    const [length, setLength] = useState(10)
    const [searchQuery, setSearchQuery] = useState('')
    const initialState = {
        first: "",
        last: "",
        email: "",
        sex: "male",
        mobile: "",
        home: "",
        city: "",
        zip: "",
        addressLineOne: "",
        addressLineTwo: "",
        dateOfBirth: "",
        state: "",
        professionalRole: "",
        speciality: "",
        websiteUrl: "",
        feePerCall: "0",
        bio: "",
        hcp: "",
        image: ""
    };
    const initailStateForErrors = {
        first: '',
        last: '',
        mobile: '',
        city: '',
        zip: '',
        email: '',
        sex: '',
        addressLineOne: '',
        dateOfBirth: '',
        state: "",
        professionalRole: "",
        speciality: "",
    }
    const [states, setStates] = useState('')
    const [professionalRole, setProfessionalRole] = useState('')
    const [specialities, setSpecialities] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [formData, setFormData] = useState(initialState)
    const [formErrors, setFormErrors] = useState(initailStateForErrors)
    const [fromInvalidMessage, setFromInvalidMessage] = useState()
    const [createOrUpdateModalShow, setCreateOrUpdateModalShow] = useState(false);
    const [isOnEdit, setIsOnEdit] = useState(false)
    const [emailModalShow, setEmailModalShow] = useState(false)
    const {current_page, per_page, total} = pagination
    const imageInpRef = useRef(null);
    const [file, setFile] = useState('');
    const [filename, setFilename] = useState('');

    const handleImageInputTrigger = e => {
        e.preventDefault()
        imageInpRef.current?.click();
    }

    const storeImageInState = e => {
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            setFilename(e.target.files[0].name);
        } else {
            setFile('')
            setFilename('')
        }
    }


    function validateFields(fieldName, value) {
        console.log('validateFields:'+fieldName+', value: '+value)
        let errors = {...formErrors}
        switch (fieldName) {
            case 'first':
                errors.first = value.length < 1 ? 'Enter valid firstname!' : '';
                break;
            case 'last':
                errors.last = value.length < 1 ? 'Enter valid lastname!' : '';
                break;
            case 'email':
                errors.email = value.length < 6 ? "Email is required!" : "";
                break;
            case 'sex':
                errors.sex = value.length < 1 ? "Sex is required!" : "";
                break;
            case 'addressLineOne':
                errors.addressLineOne = value.length < 1 ? "Address is required!" : "";
                break;
            case 'dateOfBirth':
                errors.dateOfBirth = value.length < 1 ? "Date of Birth is required!" : "";
                break;
            case 'mobile':
                errors.mobile = value.length < 10 ? 'Invalid!' : '';
                break;
            case 'city':
                errors.city = value.length < 1 ? 'Invalid!' : '';
                break;
            case 'zip':
                errors.zip = value.length < 5 ? 'Invalid!' : (value.length > 5 ? 'Invalid!' : '');
                break;
            case 'state':
                errors.state = value.length < 1 || value === 'Select...' ? 'Invalid!' : '';
                break;
            case 'professionalRole':
                errors.professionalRole = value.length < 1 || value === 'Select...' ? 'Invalid!' : '';
                break;
            case 'speciality':
                errors.speciality = value.length < 1 || value === 'Select...' ? 'Invalid!' : '';
                break;
            default:
                break;
        }
        setFormErrors(errors)
    }

    const uploadImage = async () => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            return await post('/profileImage', formData)

        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: 'Something went wrong!'
            })
        }
    }

    function handleChange(evt) {
        if (evt.target.name === 'email') {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value.toLowerCase()
            });
        } else {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value
            });
        }

        validateFields(evt.target.name, evt.target.value)
    }

    const handleClose = () => {
        setCreateOrUpdateModalShow(false)
        setEmailModalShow(false)
        setFormData(initialState)
        setFormErrors(initailStateForErrors)
        setFromInvalidMessage('')
    };

    const handleBirthDateChange = (date) => {
        setFormData({
            ...formData,
            dateOfBirth: date
        });
    }

    const handleEmailModal = (e) => {
        e.preventDefault();
        setFile('')
        setFilename('')
        setFormData(initialState)
        setErrorMessage('')
        setEmailModalShow(true)
    }

    const verifyOrInviteEmail = (e) => {
        e.preventDefault();
        // verify email and start user create journey
        getWithParams('/verify-email', {email: formData.email})
            .then(res => {
                setEmailModalShow(false)
                handleAddModal()
            })
            .catch(err => setErrorMessage(err.response.data.message))
    }

    const formValid = ({
                           home,
                           addressLineTwo,
                           hcp,
                           bio,
                           feePerCall,
                           image,
                           status,
                           websiteUrl,
                           ...rest
                       }, formErrors) => {
        console.log(formErrors)
        let isValid = false;
        let isRequired = false;
        isValid = Object.values(formErrors).every(el => el === '')
        isRequired = Object.values(rest).every(el => el !== '')

        return isValid && isRequired;
    }

    const createOrUpdate = (e) => {
        e.preventDefault();
        setFromInvalidMessage('')
        console.log(formData)
        let allOk = formValid(formData, formErrors)
        if (!allOk) {
            setFromInvalidMessage('Need to fill required fields!')
        } else {
            if (isOnEdit) { // If on edit mode handle user edit process
                if (file) {
                    uploadImage()
                        .then(res => {
                            dispatch(updateUser({...formData, image: res.data.url}))
                                .then(_ => {
                                    setCreateOrUpdateModalShow(false)
                                    index(current_page)
                                    Toast.fire({
                                        icon: 'success',
                                        title: 'Updated successfully'
                                    })
                                })
                        })
                } else {
                    dispatch(updateUser({...formData}))
                        .then(_ => {
                            setCreateOrUpdateModalShow(false)
                            index(current_page)
                            Toast.fire({
                                icon: 'success',
                                title: 'Updated successfully'
                            })
                        })
                }
            } else { // User create process here
                if (file) {
                    uploadImage()
                        .then(res => {
                            dispatch(createUser({...formData, image: res.data.url}))
                                .then(_ => {
                                    setCreateOrUpdateModalShow(false)
                                    index(current_page)
                                    Toast.fire({
                                        icon: 'success',
                                        title: 'Created successfully'
                                    })
                                })
                        })
                } else {
                    dispatch(createUser({...formData}))
                        .then(_ => {
                            setCreateOrUpdateModalShow(false)
                            index(current_page)
                            Toast.fire({
                                icon: 'success',
                                title: 'Created successfully'
                            })
                        })
                }
            }
        }
    }

    const handleAddModal = () => {
        setIsOnEdit(false)
        setFormData({
            ...formData,
            hcp: hcpID,
            role: "doctor",
            status: 'created'
        })
        getDataSet()
        setCreateOrUpdateModalShow(true)
    }

    const getDataSet = () => {
        get('/states')
            .then(res => setStates(res.data.states))
            .catch(err => console.log(err))
        get('/professionalRole')
            .then(res => setProfessionalRole(res.data.role))
            .catch(err => console.log(err))
        get('/specialities')
            .then(res => setSpecialities(res.data.specialities))
            .catch(err => console.log(err))
    }

    const handleEditModal = (id) => {
        setFile('')
        setFilename('')
        setIsOnEdit(true)
        getDataSet()
        const {
            access,
            dateOfBirth,
            notificationId,
            role,
            voipNotificationId,
            __v,
            ...rest
        } = doctors.find(doctor => doctor._id === id)
        let date = new Date(dateOfBirth)
        setFormData({dateOfBirth: date, ...rest})
        setCreateOrUpdateModalShow(true)
    };

    const handleDelete = (id) => {
        swalDel('This Doctor entity would be deleted permanently!')
            .then((result) => {
                if (result.value) {
                    del(`/user/${id}`)
                        .then(_ => {
                            Toast.fire({
                                icon: 'success',
                                title: 'Deleted!'
                            })
                            index(current_page)
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
    }

    const index = (page = 1) => {
        dispatch(getDoctors({page: page, per_page: length, search: searchQuery}))
    }
    const delayedQuery = useCallback(debounce(index, 500), [searchQuery, length])

    useEffect(() => {
        delayedQuery();
        return delayedQuery.cancel;
    }, [searchQuery, delayedQuery, length]);

    return (
        <div className="content-wrapper">
            <section className="content pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="float-left">
                                        <h2 className="card-title pt-1">Healthcare </h2>
                                        <a href='' onClick={(e) => handleEmailModal(e)} className="ml-4 card-title">
                                            <FontAwesomeIcon icon={faUserPlus}/> Add Healthcare Professional
                                        </a>
                                        <a href='' className="ml-4 card-title">
                                            {
                                                isLoading ?
                                                    (
                                                        <div className="loader"></div>
                                                    )
                                                    : ''
                                            }
                                        </a>
                                    </div>
                                    <div className="float-right">
                                        <div className="input-group">
                                            <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                                   className="form-control form-control-sm" type="text"
                                                   placeholder="Search"/>
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body" style={{overflowX: 'auto'}}>
                                    <table id="example2"
                                           className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}">
                                        <thead>
                                        <tr>
                                            <th>FIRST NAME</th>
                                            <th>LAST NAME</th>
                                            <th>EMAIL</th>
                                            <th>Role</th>
                                            <th className="text-center">ACTIONS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!!doctors && doctors.map((doctor, index) => (
                                            <tr key={index}>
                                                <td>{doctor.first}</td>
                                                <td>{doctor.last}</td>
                                                <td>{doctor.email}</td>
                                                <td>{doctor.professionalRole}</td>
                                                <td className="text-center">
                                                    <div className="btn-group btn-group-sm" role="group"
                                                         aria-label="single group">
                                                        <button type="button" className="btn btn-sm"
                                                                onClick={() => handleEditModal(doctor._id)}>
                                                            <FontAwesomeIcon icon={faEdit} className='text-green'/>
                                                            {/*<i className="fa fa-edit text-green" data-toggle="tooltip"*/}
                                                            {/*   data-placement="top" title="edit"/>*/}
                                                        </button>
                                                        <button type="button" className="btn btn-sm"
                                                                onClick={() => handleDelete(doctor._id)}>
                                                            <FontAwesomeIcon icon={faTrash} className='text-red'/>
                                                            {/*<i className="fa fa-trash text-red" data-toggle="tooltip"*/}
                                                            {/*   data-placement="top" title="delete"/>*/}
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                        </tbody>
                                    </table>

                                    {/* user email verify modal starts */}
                                    <Modal
                                        show={emailModalShow}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton className='pb-2'>
                                            <Modal.Title>Healthcare Professional Registration</Modal.Title>
                                        </Modal.Header>
                                        <form onSubmit={verifyOrInviteEmail}>
                                            <Modal.Body>
                                                <div className="form-row">
                                                    <h5 className='text-center'>Please enter the doctors's email address
                                                        to start the registration process.</h5>

                                                    <div className="mt-3 form-group col-md-12 px-3">
                                                        <input type="email" name="email" className="form-control"
                                                               value={formData.email} onChange={handleChange} required/>
                                                        {errorMessage ? <span
                                                            className="text-danger mt-2">{errorMessage}</span> : null}
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" type="submit">Submit</Button>
                                            </Modal.Footer>
                                        </form>
                                    </Modal>
                                    {/* user email verify modal ends here */}

                                    {/* doctor edit/create modal starts */}
                                    <Modal
                                        show={createOrUpdateModalShow}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton className='pb-2'>
                                            {
                                                isOnEdit ?
                                                    <Modal.Title>Existing Healthcare Professional Form</Modal.Title>
                                                    :
                                                    <Modal.Title>Add New Healthcare Professional</Modal.Title>
                                            }
                                        </Modal.Header>
                                        <form onSubmit={createOrUpdate}>
                                            <Modal.Body>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>First Name<span
                                                            className="text-danger">*</span></label>
                                                        <input type="text" name="first" className="form-control"
                                                               value={formData.first} onChange={handleChange}/>
                                                        {formErrors.first.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.first}</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Last Name<span
                                                            className="text-danger">*</span></label>
                                                        <input type="text" name="last" className="form-control"
                                                               value={formData.last} onChange={handleChange}/>
                                                        {formErrors.last.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.last}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Email<span
                                                            className="text-danger">*</span></label>
                                                        <input type="email" name="email" className="form-control"
                                                               value={formData.email} disabled/>
                                                        {formErrors.email.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.email}</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Sex<span
                                                            className="text-danger">*</span></label>
                                                        <select id="inputState" name="sex" className="form-control"
                                                                value={formData.sex} onChange={handleChange}>
                                                            <option value={"male"}>Male</option>
                                                            <option value={"female"}>Female</option>
                                                        </select>
                                                        {formErrors.sex.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.sex}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Date of Birth<span
                                                            className="text-danger">*</span></label>
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            selected={formData.dateOfBirth}
                                                            onChange={handleBirthDateChange}
                                                            placeholderText="MM/dd/yyyy"
                                                            showYearDropdown
                                                            yearDropdownItemNumber={20}
                                                            dropdownMode="select"
                                                        />
                                                        {formErrors.dateOfBirth.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.dateOfBirth}</span>
                                                        )}
                                                    </div>
                                                    {/* TODO this is empty*/}
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Role<span
                                                            className="text-danger">*</span></label>
                                                        <select id="inputState" name="professionalRole"
                                                                className="form-control"
                                                                value={formData.professionalRole}
                                                                onChange={handleChange}>
                                                            <option value="">Select...</option>
                                                            {
                                                                !!professionalRole && professionalRole.map((role, index) => (
                                                                    <option key={index} value={role}>{role}</option>))
                                                            }
                                                        </select>
                                                        {formErrors.professionalRole.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.professionalRole}</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Speciality<span
                                                            className="text-danger">*</span></label>
                                                        <select id="inputState" name="speciality"
                                                                className="form-control" value={formData.speciality}
                                                                onChange={handleChange}>
                                                            <option value="">Select...</option>
                                                            {
                                                                !!specialities && specialities.map((speciality, index) => (
                                                                    <option key={index}
                                                                            value={speciality}>{speciality}</option>))
                                                            }
                                                        </select>
                                                        {formErrors.speciality.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.speciality}</span>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Address line 1<span
                                                            className="text-danger">*</span></label>
                                                        <input type="text" name="addressLineOne"
                                                               className="form-control" value={formData.addressLineOne}
                                                               onChange={handleChange}/>
                                                        {formErrors.addressLineOne.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.addressLineOne}</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Address line 2</label>
                                                        <input type="text" name="addressLineTwo"
                                                               className="form-control" value={formData.addressLineTwo}
                                                               onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>City<span
                                                            className="text-danger">*</span></label>
                                                        <input type="text" name="city" className="form-control"
                                                               value={formData.city} onChange={handleChange}/>
                                                        {formErrors.city.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.city}</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>State<span
                                                            className="text-danger">*</span></label>
                                                        <select id="inputState" name="state" className="form-control"
                                                                value={formData.state} onChange={handleChange}>
                                                            <option value="">Select...</option>
                                                            {
                                                                !!states && states.map((state, index) => (
                                                                    <option key={index} value={state}>{state}</option>))
                                                            }
                                                        </select>
                                                        {formErrors.state.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.state}</span>
                                                        )}
                                                    </div>

                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Zip Code<span
                                                            className="text-danger">*</span></label>
                                                        <input type="text" name="zip" className="form-control"
                                                               value={formData.zip} onChange={handleChange}/>
                                                        {formErrors.zip.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.zip}</span>
                                                        )}
                                                    </div>
                                                    {/* TODO this is empty*/}
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Cell Phone<span
                                                            className="text-danger">*</span></label>
                                                        <input type="text" name="mobile" className="form-control"
                                                               value={formData.mobile} onChange={handleChange}/>
                                                        {formErrors.mobile.length > 0 && (
                                                            <span
                                                                className="text-danger">{formErrors.mobile}</span>
                                                        )}
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Home Phone</label>
                                                        <input type="text" name="home" className="form-control"
                                                               value={formData.home} onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Website URL</label>
                                                        <input type="text" name="websiteUrl" className="form-control"
                                                               value={formData.websiteUrl} onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Fee Per Call</label>
                                                        <input type="text" name="feePerCall" className="form-control"
                                                               value={formData.feePerCall} onChange={handleChange}/>
                                                    </div>
                                                </div>


                                                <div className="form-row">
                                                    <div className="form-group col-md-12 px-2 pt-2">
                                                        <input
                                                            ref={imageInpRef}
                                                            onChange={storeImageInState}
                                                            className="d-none"
                                                            type="file"
                                                        />
                                                        <button
                                                            onClick={handleImageInputTrigger}
                                                            className="btn btn-outline-primary"
                                                        >
                                                            {filename ? filename : <>Choose Image <i
                                                                className="fas fa-camera-retro"></i></>}
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    file ?
                                                        <div className="form-row">
                                                            <div className="form-group col-md-5 px-2">
                                                                <img style={{width: '100%'}}
                                                                     src={URL.createObjectURL(file)} alt=''/>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                {
                                                    !file && formData.image ?
                                                        <div className="form-row">
                                                            <div className="form-group col-md-5 px-2">
                                                                <img style={{width: '100%'}} src={formData.image}
                                                                     alt=''/>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                }
                                                <div className="form-row">
                                                    <div className="form-group col-md-12 px-2">
                                                        <label>Bio</label>
                                                        <textarea name="bio" className="form-control"
                                                                  value={formData.bio} onChange={handleChange}
                                                                  rows={3}/>
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                {!!fromInvalidMessage ? <span
                                                    className="text-danger mb-3">{fromInvalidMessage}</span> : null}
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" type="submit">Save</Button>
                                            </Modal.Footer>
                                        </form>
                                    </Modal>
                                    {/* doctor edit/create modal ends here */}

                                </div>
                                {/* /.card-body */}
                                <div className="card-footer">
                                    <div className="float-left">
                                        <div className="dataTables_info" id="example2_info" role="status"
                                             aria-live="polite">
                                            {/* <div className="float-left">
                      </div> */}
                                            {
                                                !!doctors.length > 0 ?
                                                    (
                                                        <>
                                                            <select onChange={(e) => setLength(e.target.value)}>
                                                                <option value={10}>10 entries</option>
                                                                <option value={20}>20 entries</option>
                                                                <option value={50}>50 entries</option>
                                                                <option value={100}>100 entries</option>
                                                            </select> &nbsp;
                                                            Showing {((current_page - 1) * per_page) + 1} to {total > current_page * per_page ? current_page * per_page : total} of {total} entries
                                                        </>
                                                    )
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        <Pagination
                                            activePage={parseInt(current_page)}
                                            itemsCountPerPage={parseInt(per_page)}
                                            totalItemsCount={parseInt(total)}
                                            // pageRangeDisplayed={5}
                                            onChange={(current_page) => index(current_page)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    )
}

export default Healthcare