import React from 'react'
import AppRoutes from './routes/AppRoutes'
// import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return ( 
    <AppRoutes />
  );
}

export default App;
