import React, {useState, useEffect} from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useParams} from 'react-router-dom';
import AfterRegisterNote from '../components/AfterRegisterNote';
import {get, put} from '../utils/fetchAPI';

function Register() {
    const {id} = useParams()
    const {accessToken} = useParams()
    const initialState = {
        first: "",
        last: "",
        email: "",
        password: "",
        confirm_password: "",
        sex: "male",
        mobile: "",
        home: "",
        state: "",
        city: "",
        zip: "",
        addressLineOne: "",
        addressLineTwo: "",
        dateOfBirth: "",
    };
    const initailStateForErrors = {
        first: "",
        last: "",
        password: '',
        confirm_password: '',
        mobile: '',
        home: '',
        zip: '',
    }
    const [formData, setFormData] = useState(initialState)
    const [formErrors, setFormErrors] = useState(initailStateForErrors)
    const [stateList, setStateList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')
    const [fromInvalidMessage, setFromInvalidMessage] = useState()
    const [showAfterRegisterNote, setShowAfterRegisterNote] = useState(false)
    const [hcpImage, setHcpImage] = useState('')
    const [hcpName, setHcpName] = useState('')

    function handleChange(evt) {
        if (evt.target.name === 'password') {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
                confirm_password: ''
            });
        } else {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value,
            })
        }
        validateFields(evt.target.name, evt.target.value)
    }

    const handleBirthDateChange = (date) => {
        setFormData({
            ...formData,
            dateOfBirth: date
        });
    }

    function validateFields(fieldName, value) {
        let errors = {...formErrors}
        switch (fieldName) {
            case 'first':
                errors.first = value.length < 1 ? 'Enter valid firstname!' : '';
                break;
            case 'last':
                errors.last = value.length < 1 ? 'Enter valid lastname!' : '';
                break;
            case 'password':
                errors.password = value.length < 6 ? "Required atleast 6 characters!" : "";
                break;
            case 'confirm_password':
                errors.confirm_password = value !== formData.password ? "Password doesn't match!" : "";
                break;
            case 'mobile':
                errors.mobile = value.length < 10 ? 'Invalid!' : (value.length > 10 ? 'Invalid!' : '');
                break;
            // case 'home':
            //     errors.home = value.length < 10 ? 'Invalid!' : (value.length > 10 ? 'Invalid!' : '');
            //     break;
            case 'zip':
                errors.zip = value.length < 5 ? 'Invalid!' : (value.length > 5 ? 'Invalid!' : '');
                break;

            default:
                break;
        }
        setFormErrors(errors)
    }

    const formValid = ({home, addressLineTwo, hcp, ...rest}, formErrors) => {
        let isValid = false;
        let isRequired = false;
        isValid = Object.values(formErrors).every(el => el === '')
        isRequired = Object.values(rest).every(el => el !== '')

        return isValid && isRequired;
    }
    const registerUser = (e) => {
        e.preventDefault()
        setFromInvalidMessage('')
        console.log(formData)
        let allOk = formValid(formData, formErrors)
        if (!allOk) {
            setFromInvalidMessage('Need to fill required fields!')
        } else {
            // now update data
            put(`/completeRegistrationFromLink/${id}/${accessToken}`, formData)
                .then(res => setShowAfterRegisterNote(true))
                .catch(err => console.log(err))
        }
    }
    const getStatus = () => {
        get('/states')
            .then(res => setStateList(res.data.states))
            .catch(err => console.log(err))
    }
    const findUser = () => {
        setIsLoading(true)
        setError('')
        get(`/verifyAccess/${id}/${accessToken}`)
            .then(res => {
                const {email} = res.data;
                setHcpImage(res.data.hcp.logo??'')
                setHcpName(res.data.hcp.name??'')
                setFormData({
                    ...formData,
                    email,
                });
            })
            .catch(err => setError('Invalid URL!'))
            .finally(() => setIsLoading(false))
    }
    useEffect(() => {
        // document.getElementById('root').classList.add('text-center')
        // document.body.classList.add('register-page');
        getStatus()
        findUser()
        return () => {
            //document.body.classList.remove('register-page');
        };
    }, [id]);

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xs-6">
                    <div className="register-box registration-form">
                        <div className="register-logo" style={{marginBottom: 0}}>
                            <div className="text-center" style={{marginTop: 25}}>
                                {
                                    hcpImage.length>0 ?
                                        <img src={hcpImage}
                                             className="rounded img-fluid shadow p-3 mb-2 mt-2 bg-white rounded"
                                             alt="..." style={{width: 100}}/>
                                        :''
                                }
                            </div>
                        </div>
                        <div className="card">
                            {
                                isLoading ?
                                    <div className="justify-content-center" style={{height:300}}>
                                        <div className="h-100 d-flex align-items-center justify-content-center">
                                            <div className="loader" style={{margin:"auto"}}></div>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {
                                            error ?
                                                <div className="text-center card-body register-card-body m-3">
                                                    <span className="text-danger">{error}</span>
                                                </div>
                                                :
                                                <>
                                                    {
                                                        !showAfterRegisterNote ?
                                                            <div className="card-body p-2 register-card-body m-3">
                                                                <h5 style={{fontSize:18}}> Complete registration to reach all the healthcare professionals from <span style={{color:"blue"}}>{hcpName}</span> with real-time video visits.</h5>
                                                                <form onSubmit={registerUser}>
                                                                    {!!fromInvalidMessage ? <span
                                                                        className="text-danger mb-3">{fromInvalidMessage}</span> : null}
                                                                    {/* /.form-box */}
                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>First Name <span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="text" name="first"
                                                                                   className={"form-control " + (formErrors.first.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.first}
                                                                                   onChange={handleChange}
                                                                                   autoComplete="off"/>
                                                                            {formErrors.first.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.first}</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Last Name <span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="text" name="last"
                                                                                   className={"form-control " + (formErrors.last.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.last}
                                                                                   onChange={handleChange}/>
                                                                            {formErrors.last.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.last}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Date of Birth<span
                                                                                className="text-danger">*</span></label>
                                                                            <DatePicker
                                                                                className="form-control"
                                                                                dateFormat="MM/dd/yyyy"
                                                                                selected={formData.dateOfBirth}
                                                                                onChange={handleBirthDateChange}
                                                                                placeholderText="MM/dd/yyyy"
                                                                                showYearDropdown
                                                                                yearDropdownItemNumber={20}
                                                                                dropdownMode="select"
                                                                            />
                                                                        </div>

                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Sex<span
                                                                                className="text-danger">*</span></label>
                                                                            <select id="inputSex" name="sex"
                                                                                    className="form-control"
                                                                                    value={formData.sex}
                                                                                    onChange={handleChange}>
                                                                                <option value={"male"}>Male</option>
                                                                                <option value={"female"}>Female</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Cell Phone<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="text" name="mobile"
                                                                                   className={"form-control " + (formErrors.mobile.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.mobile}
                                                                                   onChange={handleChange}/>
                                                                            {formErrors.mobile.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.mobile}</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Home Phone</label>
                                                                            <input type="text" name="home"
                                                                                   className={"form-control " + (formErrors.home.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.home}
                                                                                   onChange={handleChange}/>
                                                                            {formErrors.home.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.home}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Address line 1<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="text" name="addressLineOne"
                                                                                   className="form-control"
                                                                                   value={formData.addressLineOne}
                                                                                   onChange={handleChange}/>
                                                                        </div>
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Address line 2</label>
                                                                            <input type="text" name="addressLineTwo"
                                                                                   className="form-control"
                                                                                   value={formData.addressLineTwo}
                                                                                   onChange={handleChange}/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>City<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="text" name="city"
                                                                                   className="form-control"
                                                                                   value={formData.city}
                                                                                   onChange={handleChange}/>
                                                                        </div>
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Zip Code<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="number" name="zip"
                                                                                   className={"form-control " + (formErrors.zip.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.zip}
                                                                                   onChange={handleChange}/>
                                                                            {formErrors.zip.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.zip}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>State<span
                                                                                className="text-danger">*</span></label>
                                                                            <select id="inputState" name="state"
                                                                                    className="form-control"
                                                                                    value={formData.state}
                                                                                    onChange={handleChange}>
                                                                                <option value="">Select...</option>
                                                                                {
                                                                                    !!stateList && stateList.map((state, index) => (
                                                                                        <option key={index}
                                                                                                value={state}>{state}</option>))
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Email<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="email" name="email"
                                                                                   className="form-control"
                                                                                   value={formData.email} disabled/>
                                                                        </div>
                                                                    </div>

                                                                    <div className="form-row">
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Password<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="password" name="password"
                                                                                   className={"form-control " + (formErrors.password.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.password}
                                                                                   onChange={handleChange}/>
                                                                            {formErrors.password.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.password}</span>
                                                                            )}
                                                                        </div>
                                                                        <div className="form-group col-md-6 px-2">
                                                                            <label>Confirm Password<span
                                                                                className="text-danger">*</span></label>
                                                                            <input type="password"
                                                                                   name="confirm_password"
                                                                                   className={"form-control " + (formErrors.confirm_password.length > 0 ? 'is-invalid' : '')}
                                                                                   value={formData.confirm_password}
                                                                                   onChange={handleChange}/>
                                                                            {formErrors.confirm_password.length > 0 && (
                                                                                <span
                                                                                    className="text-danger">{formErrors.confirm_password}</span>
                                                                            )}
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        {/* /.col */}
                                                                        <div className="col-md-4 offset-md-8">
                                                                            <button type="submit"
                                                                                    className="btn btn-primary btn-block">Register
                                                                            </button>
                                                                        </div>
                                                                        {/* /.col */}
                                                                    </div>
                                                                </form>
                                                            </div>
                                                            :
                                                            <AfterRegisterNote/>
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </div>
                        {/* /.card */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Register