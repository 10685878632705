import React from 'react'
import {BrowserRouter, Navigate, Routes, Route} from 'react-router-dom';
import PrivateRoute from './PrivateRoute'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Patient from '../pages/Patient';
import Register from '../pages/Register';
import Healthcare from '../pages/Healthcare';
import Staff from '../pages/Staff';
import Analytics from '../pages/Analytics';
import RTCVideoCall from '../pages/RTCVideoCall';
import PendingRequest from '../pages/PendingRequest';
import SetPassword from "../pages/SetPassword";
import PublicRoutes from "./PublicRoute";

function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                {/*<Route element={<WebRoutes/>}>*/}
                {/*    <Route path="/" element={<LandingPage/>}/>*/}
                {/*    <Route path="/patients" element={<Patients/>}/>*/}
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/" element={<Login/>}/>
                {/*    <Route path="/providers" element={<Providers/>}/>*/}
                {/*    <Route path="/5-benefits-of-telemedicine" element={<Benefits/>}/>*/}
                {/*    <Route path="*" element={<Navigate replace to="/"/>}/>*/}
                {/*</Route>*/}
                <Route element={<PrivateRoute/>}>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/patient" element={<Patient/>}/>
                    <Route path="/healthcare" element={<Healthcare/>}/>
                    <Route path="/staff" element={<Staff/>}/>
                    <Route path="/analytics" element={<Analytics/>}/>
                    <Route path="/pending-requests" element={<PendingRequest/>}/>
                </Route>
                <Route element={<PublicRoutes/>}>
                    <Route path="/register/:id/:accessToken" element={<Register/>}/>
                </Route>
                <Route path="/waiting-room/:id" element={<RTCVideoCall/>}/>
                <Route path="/set-password/:id/:accessToken" element={<SetPassword/>}/>
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes