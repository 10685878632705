import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {removeAuthInfo} from '../store/slices/authSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOutAlt, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons'

function AppNavbar() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')))
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch(removeAuthInfo())
        navigate('/login');
    }
    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                        <FontAwesomeIcon icon={faBars}/></a>

                </li>
            </ul>
            <h3> {(!!user && !!user?.hcp) ? `${user.hcp.name} Web Portal` : 'Web Portal'}</h3>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Navbar Search */}
                <li className="nav-item dropdown">
                    <button onClick={handleLogout} type="button" className="btn btn-link" style={{color: "black"}}>
                        <FontAwesomeIcon icon={faSignOutAlt}/> Logout
                    </button>
                </li>
                <li className="nav-item">
                    <a className="nav-link" data-widget="fullscreen" href="#" role="button">
                        <FontAwesomeIcon icon={faExpandArrowsAlt}/>
                    </a>
                </li>
            </ul>
        </nav>

    )
}

export default AppNavbar