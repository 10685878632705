import React, {useState, useEffect, useCallback} from 'react'
import Pagination from "react-js-pagination";
import {useDispatch, useSelector} from 'react-redux';
import {getPatients, createUser, updateUser} from '../store/slices/hcpSlice';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import debounce from 'lodash/debounce'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {swalDel, Toast} from '../utils/SwalUti';
import {get, getWithParams, post, del} from '../utils/fetchAPI';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUserPlus, faEnvelope, faEdit, faTrash} from '@fortawesome/free-solid-svg-icons'


function Patient() {
    const dispatch = useDispatch()
    const {patients, pagination, isLoading} = useSelector(state => state.hcp)
    const hcpID = useSelector(state => state.auth.user.hcp._id)
    const [length, setLength] = useState(10)
    const [searchQuery, setSearchQuery] = useState('')
    const [stateList, setStateList] = useState([])
    const [doctorList, setDoctorList] = useState([])
    const initialState = {
        first: "",
        last: "",
        email: "",
        sex: "male",
        mobile: "",
        home: "",
        state: "",
        city: "",
        zip: "",
        addressLineOne: "",
        addressLineTwo: "",
        dateOfBirth: null,
        doctors: "",
        hcp: ""
    };
    const [errorMessage, setErrorMessage] = useState('')
    const [formData, setFormData] = useState(initialState)
    const [eidtOrUpdateModalShow, setEidtOrUpdateModalShow] = useState(false);
    const [isOnEdit, setIsOnEdit] = useState(false)
    const [emailModalShow, setEmailModalShow] = useState(false)
    const [isOnVerifyEmail, setIsOnVerifyEmail] = useState(false)
    const {current_page, per_page, total} = pagination

    function handleChange(evt) {
        if (evt.target.name === 'email') {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value.toLowerCase()
            });
        } else {
            setFormData({
                ...formData,
                [evt.target.name]: evt.target.value
            });
        }
    }

    const handleClose = () => {
        setEidtOrUpdateModalShow(false)
        setEmailModalShow(false)
    };

    const handleDoctors = (data) => {
        setFormData({
            ...formData,
            doctors: data
        });
    }

    const handleBirthDateChange = (date) => {
        setFormData({
            ...formData,
            dateOfBirth: date
        });
    }

    const handleEmailModal = (data) => (e) => {
        e.preventDefault();
        setFormData(initialState)
        setErrorMessage('')
        if (data === 'verify') {
            setIsOnVerifyEmail(true)
        } else if (data === 'invite') {
            setIsOnVerifyEmail(false)
        }
        setEmailModalShow(true)
    }

    const verifyOrInviteEmail = (e) => {
        e.preventDefault();
        if (isOnVerifyEmail) {
            // verify email and start user create journey
            getWithParams('/verify-email', {email: formData.email})
                .then(res => {
                    setEmailModalShow(false)
                    handleAddModal()
                })
                .catch(err => setErrorMessage(err.response.data.message))
        } else {
            // invite email logic here
            post('/sendRegistrationLink', {email: formData.email, hcpID, role: 'patient'})
                .then(res => {
                    setEmailModalShow(false)
                    Toast.fire({
                        icon: 'success',
                        title: 'Invitation sent!'
                    })
                })
                .catch(err => setErrorMessage(err.response.data.message))
        }
    }

    const createOrUpdate = (e) => {
        e.preventDefault();
        // If on edit mode handle user edit process
        if (isOnEdit) {
            dispatch(updateUser(formData))
                .then(result => {
                    if(result.error){
                        Toast.fire({
                            icon: 'error',
                            title: result.payload.message
                        })
                    }else {
                        setEidtOrUpdateModalShow(false)
                        index(current_page)
                        Toast.fire({
                            icon: 'success',
                            title: 'Updated successfully'
                        })
                    }
                })
        }
        // User create process here
        else {
            console.log(formData)
            formData.status = 'created'
            formData.role = 'patient'
            console.log(formData)
            dispatch(createUser(formData))
                .then(_ => {
                    setEidtOrUpdateModalShow(false)
                    index(current_page)
                    Toast.fire({
                        icon: 'success',
                        title: 'Created successfully'
                    })
                })
        }
    }

    const handleAddModal = () => {
        setIsOnEdit(false)
        getStatus()
        getDoc()
            .then(res => {
                setDoctorList(res.data)
                setFormData({
                    ...formData,
                    hcp: hcpID,
                    role: "patient",
                    doctors: res.data
                })
                setEidtOrUpdateModalShow(true)
            })
    }

    const handleEditModal = (id) => {
        setIsOnEdit(true)
        if (doctorList.length <= 0) {
            getDoc()
                .then(res => setDoctorList(res.data))
        }
        const {
            access,
            dateOfBirth,
            notificationId,
            role,
            voipNotificationId,
            __v,
            ...rest
        } = patients.find(patient => patient._id === id)
        let date = dateOfBirth ? new Date(dateOfBirth) : null
        setFormData({dateOfBirth: date, ...rest})
        setEidtOrUpdateModalShow(true)
        getStatus()
    };

    const handleDelete = (id) => {
        swalDel('This Patient entity would be deleted permanently!')
            .then((result) => {
                if (result.value) {
                    del(`/user/${id}`)
                        .then(_ => {
                            Toast.fire({
                                icon: 'success',
                                title: 'Deleted!'
                            })
                            index(current_page)
                        })
                        .catch(error => {
                            console.log(error);
                        })
                }
            })
    }
    const getStatus = () => {
        get('/states')
            .then(res => setStateList(res.data.states))
            .catch(err => console.log(err))
    }
    const getDoc = () => get(`/hcp/${hcpID}/doctors`);

    const index = (page = 1) => {
        dispatch(getPatients({page: page, per_page: length, search: searchQuery}))
    }
    const delayedQuery = useCallback(debounce(index, 500), [searchQuery, length])

    useEffect(() => {
        delayedQuery();
        return delayedQuery.cancel;
    }, [searchQuery, delayedQuery, length]);

    return (
        <div className="content-wrapper">
            <section className="content pt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="float-left">
                                        <h2 className="card-title pt-1">Patients</h2>
                                        <a href='' onClick={handleEmailModal('verify')} className="ml-4 card-title">
                                            <FontAwesomeIcon icon={faUserPlus}/> Add Patient
                                        </a>
                                        <a href='' onClick={handleEmailModal('invite')} className="ml-4 card-title">
                                            <FontAwesomeIcon icon={faEnvelope}/> Email Invitation
                                        </a>
                                        <a href='' className="ml-4 card-title">
                                            {
                                                isLoading ?
                                                    (
                                                        <div className="loader"></div>
                                                    )
                                                    : ''
                                            }
                                        </a>
                                    </div>
                                    <div className="float-right">
                                        <div className="input-group">
                                            <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                                                   className="form-control form-control-sm" type="text"
                                                   placeholder="Search"/>
                                            {/* <div className="input-group-append" onClick={() => setSearchQuery('')} >
                          <button className="btn btn-sm">
                            <i className="fas fa-sync fa-fw text-red"></i>
                          </button>
                        </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body" style={{overflowX: 'auto'}}>

                                    <table id="example2"
                                           className="table table-hover table-sm table-responsive{-sm|-md|-lg|-xl}">
                                        <thead>
                                        <tr>
                                            <th>FIRST NAME</th>
                                            <th>LAST NAME</th>
                                            <th>EMAIL</th>
                                            <th>CELLPHONE</th>
                                            <th>INSURANCE</th>
                                            <th className="text-center">ACTIONS</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {!!patients && patients.map((patient, index) => (
                                            <tr key={index}>
                                                <td>{patient.first}</td>
                                                <td>{patient.last}</td>
                                                <td>{patient.email}</td>
                                                <td>{patient.mobile}</td>
                                                <td>{patient.insurance}</td>
                                                <td className="text-center">
                                                    <div className="btn-group btn-group-sm" role="group"
                                                         aria-label="single group">
                                                        <button type="button" className="btn btn-sm"
                                                                onClick={() => handleEditModal(patient._id)}>
                                                            <FontAwesomeIcon icon={faEdit} className='text-green'/>
                                                        </button>
                                                        {/* <button type="button" className="btn btn-sm" onClick={() => handleDelete(patient._id)} >
                                <i className="fa fa-trash text-red" data-toggle="tooltip" data-placement="top" title="delete" />
                              </button> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                        }
                                        </tbody>
                                    </table>

                                    {/* user email verify modal starts */}
                                    <Modal
                                        show={emailModalShow}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton className='pb-2'>
                                            {
                                                isOnVerifyEmail ?
                                                    <Modal.Title>Patient Registration</Modal.Title>
                                                    :
                                                    <Modal.Title>Invitation Request</Modal.Title>
                                            }
                                        </Modal.Header>
                                        <form onSubmit={verifyOrInviteEmail}>
                                            <Modal.Body>
                                                <div className="form-row">
                                                    {
                                                        isOnVerifyEmail ?
                                                            <h5 className='text-center'>Please enter the patient's email
                                                                address to start the registration process.</h5>
                                                            :
                                                            <h5 className='text-center'>Enter patient's email to send
                                                                invitation to join.</h5>
                                                    }
                                                    <div className="mt-3 form-group col-md-12 px-3">
                                                        <input type="email" name="email" className="form-control"
                                                               value={formData.email} onChange={handleChange} required/>
                                                        {errorMessage ? <span
                                                            className="text-danger mt-2">{errorMessage}</span> : null}
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" type="submit">Submit</Button>
                                            </Modal.Footer>
                                        </form>
                                    </Modal>
                                    {/* user email verify modal ends here */}

                                    {/* patient edit/create modal starts */}
                                    <Modal
                                        show={eidtOrUpdateModalShow}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                    >
                                        <Modal.Header closeButton className='pb-2'>
                                            {
                                                isOnEdit ?
                                                    <Modal.Title>Existing Patient Form</Modal.Title>
                                                    :
                                                    <Modal.Title>Add New Patient</Modal.Title>
                                            }
                                        </Modal.Header>
                                        <form onSubmit={createOrUpdate}>
                                            <Modal.Body>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>First Name</label>
                                                        <input type="text" name="first" className="form-control"
                                                               value={formData.first} onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Last Name</label>
                                                        <input type="text" name="last" className="form-control"
                                                               value={formData.last} onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Date of Birth</label>
                                                        <DatePicker
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            selected={formData.dateOfBirth}
                                                            onChange={handleBirthDateChange}
                                                            placeholderText="MM/dd/yyyy"
                                                            showYearDropdown
                                                            yearDropdownItemNumber={20}
                                                            dropdownMode="select"
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Sex</label>
                                                        <select id="inputState" name="sex" className="form-control"
                                                                value={formData.sex} onChange={handleChange}>
                                                            <option value={"male"}>Male</option>
                                                            <option value={"female"}>Female</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Cell Phone</label>
                                                        <input type="text" name="mobile" className="form-control"
                                                               value={formData.mobile}
                                                               onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Home Phone</label>
                                                        <input type="text" name="home" className="form-control"
                                                               value={formData.home} onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Address line 1</label>
                                                        <input type="text" name="addressLineOne"
                                                               className="form-control" value={formData.addressLineOne}
                                                               onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Address line 2</label>
                                                        <input type="text" name="addressLineTwo"
                                                               className="form-control" value={formData.addressLineTwo}
                                                               onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>City</label>
                                                        <input type="text" name="city" className="form-control"
                                                               value={formData.city} onChange={handleChange}/>
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Zip Code</label>
                                                        <input type="text" name="zip" className="form-control"
                                                               value={formData.zip} onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>State</label>
                                                        <select id="inputState" name="state" className="form-control"
                                                                value={formData.state} onChange={handleChange}>
                                                            <option value="">Select...</option>
                                                            {
                                                                !!stateList && stateList.map((state, index) => (
                                                                    <option key={index} value={state}>{state}</option>))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-6 px-2">
                                                        <label>Email</label>
                                                        <input type="email" name="email" className="form-control"
                                                               value={formData.email} onChange={handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-12 px-2">
                                                        <label>Healthcare Professional</label>
                                                        <Select
                                                            isMulti
                                                            name="first"
                                                            options={doctorList}
                                                            value={formData.doctors}
                                                            getOptionLabel={option => `${option.first} ${option.last}`}
                                                            getOptionValue={option => option._id}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            onChange={handleDoctors}
                                                        />
                                                    </div>
                                                </div>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={handleClose}>
                                                    Close
                                                </Button>
                                                <Button variant="primary" type="submit">Save</Button>
                                            </Modal.Footer>
                                        </form>
                                    </Modal>
                                    {/* patient edit/create modal ends here */}

                                </div>
                                {/* /.card-body */}
                                <div className="card-footer">
                                    <div className="float-left">
                                        <div className="dataTables_info" id="example2_info" role="status"
                                             aria-live="polite">
                                            {/* <div className="float-left">
                      </div> */}
                                            {
                                                !!patients.length > 0 ?
                                                    (
                                                        <>
                                                            <select onChange={(e) => setLength(e.target.value)}>
                                                                <option value={10}>10 entries</option>
                                                                <option value={20}>20 entries</option>
                                                                <option value={50}>50 entries</option>
                                                                <option value={100}>100 entries</option>
                                                            </select> &nbsp;
                                                            Showing {((current_page - 1) * per_page) + 1} to {total > current_page * per_page ? current_page * per_page : total} of {total} entries
                                                        </>
                                                    )
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className="float-right">
                                        <Pagination
                                            activePage={parseInt(current_page)}
                                            itemsCountPerPage={parseInt(per_page)}
                                            totalItemsCount={parseInt(total)}
                                            // pageRangeDisplayed={5}
                                            onChange={(current_page) => index(current_page)}
                                            itemClass="page-item"
                                            linkClass="page-link"
                                        />
                                    </div>
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                        {/* /.col */}
                    </div>
                    {/* /.row */}
                </div>
                {/* /.container-fluid */}
            </section>
        </div>
    )
}

export default Patient